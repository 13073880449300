import React from 'react';
import Nav from './Nav';
import Footer from './Footer/footer'


export default ({children}) => (
  <div>
    <Nav />
      {children}
    <Footer/>
  </div>
)
