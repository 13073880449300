import React from "react"
import styles from "./footer.module.scss"
import { Link } from "gatsby"
import Logo from "../../images/iQube-Logo.png"
// import SocialLinks from "../SocialLinks/sociallinks.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

export default () => (
  <footer className={styles.footer}>
    <div className={styles.top}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.about}>
            <Link cover duration={1} bg = "#e7ecef" to="/" className={styles.logo}>
                <img src={Logo} alt="iQube Logo"/>   
            </Link>
            <h5>
                We are committed to building innovative solutions to complex problems and new idea across all sectors. 
            </h5>
          </div>
          <div className={styles.nav}>
            <div className={styles.navLink}>
              <h4>
                Company
              </h4>
              <Link to="/about" className={styles.footerLink}>
                About Us
              </Link>
              <Link to="/solutions-built" className={styles.footerLink}>
                Solutions Built
              </Link>
              <Link to="/careers" className={styles.footerLink}>
                Careers
              </Link>
              <Link to="/contact" className={styles.footerLink}>
                Contact
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.social}>
          <h5><a href="https://twitter.com/iqubebase" target="_blank" rel="noopener noreferrer">Twitter</a></h5>
          {/* <h5>Instagram</h5> */}
          <h5><a href="https://www.linkedin.com/company/iqube-labs/about/" target="_blank" rel="noopener noreferrer">LinkedIn</a></h5>
        </div>
       </div>
      </div>

      <div className={styles.bottom}>
        <div className={styles.container}>
        <div className={styles.content}>
         <div className={styles.contact}>
            <h4>Office</h4>
            <h5>
              1st floor, Gubabi Plaza, <br />
              194, Herbert Macualay Way, <br />
              (Near Makoko Bus-Stop) Adekunle, Yaba. <br />
              Lagos, Nigeria.
            </h5>
          </div>
          <div className={styles.email}>
            <h4>Email</h4>
            <a href="mailto:support@iqubelabs.com" className={styles.support}>
                    <FontAwesomeIcon 
                      className={styles.icon}
                      icon={faEnvelope}/>support@iqubelabs.com
                  </a>
          </div>
        </div>
          <div className={styles.footerCopyright}>
            <p>
              © {new Date().getFullYear()} | iQubeLabs All Rights Reserved. <span><Link className={styles.link} to="/privacy">Terms and Privacy Policy</Link></span>
            </p>
          </div>
        </div>
      </div>  
  </footer>
)
