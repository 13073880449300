import React, {Component} from 'react'
import { Link } from 'gatsby';
import classNames from 'classnames'
import styles from './nav.module.scss'
import Logo from '../../images/iQube-Logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

class Nav extends Component {
  state = {
    menuOpen: false,
    dropDownOpen: false,
  }

  handleResize = () => {
    this.setState({
      menuOpen: false
    })
  }

  toggleMenu = () => {
    this.setState(prevState => ({
      menuOpen: !prevState.menuOpen
    }))
  }
  
  handleLinkClick = () => {
    this.setState({
      menuOpen: false
    })
  }

  toggleDropDown = () => {
    this.setState(prevState => ({
      dropDownOpen: !prevState.dropDownOpen
    }))
  }

  componentDidMount () {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount(){
    window.addEventListener('resize', this.handleResize)
  }

  render () {
  const isMenuOpen = this.state.menuOpen

    return  (
      <nav className={styles.nav}>
        <div className={styles.container}>
          <Link 
            cover
            duration={1}
            bg = "#e7ecef"
            to="/"    
            className={styles.logo}
            >
              <img src={Logo} alt="iQube Logo"/>   
          </Link>

          <div className={classNames(styles.hamburger, {[styles.open]: this.state.menuOpen === true})} onClick={this.toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          
          {
            isMenuOpen ? (
              <div className={styles.mobileMenu}>
                <div className={styles.container}>
                  <Link to="/" className={styles.link} onClick={this.handleLinkClick}>Home</Link>
                  <Link to="/about/" className={styles.link} onClick={this.handleLinkClick}>About Us</Link>
                  <Link to="/solutions-built" className={styles.link} onClick={this.handleLinkClick}>Solutions Built</Link>
                  <Link to="/our-products" className={styles.link} onClick={this.handleLinkClick}>Our Products</Link>
                  
                  <Link to="/insights" className={styles.link} onClick={this.handleLinkClick}>Insights</Link>
                  <Link to="/careers/" className={styles.link} onClick={this.handleLinkClick}>Careers</Link>
                  <Link to="/build-with-us/" className={styles.link} onClick={this.handleLinkClick}>Build With Us</Link>
                  <Link to="/contact/" className={styles.link} onClick={this.handleLinkClick}>Contact</Link>
                   <a href="mailto:support@iqubelabs.com" className={styles.support}>
                    <FontAwesomeIcon 
                      className={styles.icon}
                      icon={faEnvelope}/>support@iqubelabs.com
                  </a>
                </div>
              </div>
            ) : null
          }
          
          <div className={styles.desktopMenu}>
            <div className={styles.navLinks}>
              <div className={styles.navLeft}>
                <Link duration={1} cover bg="#e7ecef" to="/" activeClassName={styles.active} className={styles.link}>Home</Link>
                <Link duration={1} cover bg="#e7ecef" to="/about/" className={styles.link}  activeClassName={styles.active}>About Us</Link>
                <Link duration={1} cover bg="#e7ecef" to="/solutions-built" className={styles.link}  activeClassName={styles.active}>Solutions Built</Link>
                <Link duration={1} cover bg="#e7ecef" to="/our-products" className={styles.link}  activeClassName={styles.active}>Our Products</Link>
              </div>

              <div className={styles.navRight}>

                <Link duration={1} cover bg="#e7ecef" to="/insights/" className={styles.link}  activeClassName={styles.active}>Insights</Link>
                <Link duration={1} cover bg="#e7ecef" to="/careers/" className={styles.link}  activeClassName={styles.active}>Careers</Link>
                <Link duration={1} cover bg="#e7ecef" to="/build-with-us/" className={styles.link}  activeClassName={styles.active}>Build With Us</Link>
                <Link duration={1} cover bg="#e7ecef" to="/contact/" className={styles.link}  activeClassName={styles.active}>Contact</Link>
                <span>|</span>
                <a href="mailto:support@iqubelabs.com" className={styles.support}>
                  <FontAwesomeIcon 
                      className={styles.icon}
                      icon={faEnvelope}/>support@iqubelabs.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Nav